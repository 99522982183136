<template>
  <div>
    <component
        v-if="notEmpty(testJSON)"
        :is="testJSON.type"
        :key="testJSON.key"
        :obj="testJSON"
    ></component>
  </div>
</template>

<script>

export default {
  data () {
    return {
      testJSON: {
        'key': 'Root',
        'type': 'LhRoot',
        'actions': [],
        'attributes':
            {
              'version':
                  '0.0.1',
              'pageTitle': 'About dev',
              'backgroundUrl': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse.svg'
            }
        ,
        'styles':
            [], 'children':
            [{
              'key': 'Root_Sidebar',
              'type': 'LhSidebar',
              'actions': [],
              'attributes': [],
              'styles': [],
              'children': [{
                'key': 'root-menu-logo-client',
                'type': 'LhImage',
                'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                'attributes': {
                  'src': 'https://api.dev.automationtool.technieken.be/assets/img/logo.svg',
                  'collapse-src': 'https://api.dev.automationtool.technieken.be/assets/img/logo_small.svg',
                  'class': 'client-logo'
                },
                'styles': { 'cursor': 'pointer' },
                'children': []
              },
                {
                'key': 'root-menu-scroll-content',
                'type': 'LhSidebarScrollContent',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-nav-list',
                  'type': 'LhNavList',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-nav-list-item-1',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_INDEX', 'action': '/', 'icon': 'home' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-2',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CLIENTS', 'action': '/clients', 'icon': 'file-alt' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-3',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PROJECTS', 'action': '/projects', 'icon': 'chart-pie' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-4',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_PAGES', 'action': '/pages', 'icon': 'users' },
                    'styles': [],
                    'children': []
                  }, {
                    'key': 'root-menu-nav-list-item-5',
                    'type': 'LhNavListItem',
                    'actions': [],
                    'attributes': { 'content': 'SIDEBAR_CONFIG', 'action': '/users', 'icon': 'cog' },
                    'styles': [],
                    'children': [{
                      'key': 'root-menu-nav-sub-list-5',
                      'type': 'LhNavList',
                      'actions': [],
                      'attributes': [],
                      'styles': [],
                      'children': [{
                        'key': 'root-menu-nav-sub-list-5-item-1',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_USERS', 'action': '/users' },
                        'styles': [],
                        'children': []
                      }, {
                        'key': 'root-menu-nav-sub-list-5-item-2',
                        'type': 'LhNavListItem',
                        'actions': [],
                        'attributes': { 'content': 'SIDEBAR_BLOCKS', 'action': '/pageblocks' },
                        'styles': [],
                        'children': []
                      }]
                    }]
                  }]
                }]
              },
                {
                'key': 'root-menu-logo-group',
                'type': 'LhDiv',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-logo-lighthouse',
                  'type': 'LhImage',
                  'actions': [{ 'url': '/', 'type': 'redirect', 'event': 'click' }],
                  'attributes': {
                    'src': 'https://api.dev.automationtool.technieken.be/assets/img/lighthouse_logo_grey.svg',
                    'class': 'lighthouse-logo'
                  },
                  'styles': { 'cursor': 'pointer' },
                  'children': []
                }]
              }, {
                'key': 'root-menu-button-group',
                'type': 'LhButtonGroup',
                'actions': [],
                'attributes': [],
                'styles': [],
                'children': [{
                  'key': 'root-menu-col-1',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [{
                    'key': 'root-menu-user-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/settings', 'type': 'update', 'method': 'GET', 'event': 'click' }],
                    'attributes': { 'icon': 'user-cog', 'class': 'sidebar-user-settings-button' },
                    'styles': [],
                    'children': []
                  }]
                }, {
                  'key': 'root-menu-col-2',
                  'type': 'LhCol',
                  'actions': [],
                  'attributes': [],
                  'styles': [],
                  'children': [
                      {
                    'key': 'root-menu-logout-button',
                    'type': 'LhIconButton',
                    'actions': [{ 'url': '/logout', 'type': 'redirect', 'method': 'POST', 'event': 'click' }],
                    'attributes': { 'icon': 'sign-out-alt', 'class': 'sidebar-user-logout-button' },
                    'styles': [],
                    'children': []
                  }
                  ]
                },
                  {
                    'key': 'root-menu-col-3',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'toggle-translations-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: 'translations-toggle-view',
                            type: 'emit',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'toggle-translations-button',
                          icon: 'globe',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  },
                  {
                    'key': 'root-menu-col-4',
                    'type': 'LhCol',
                    'actions': [],
                    'attributes': [],
                    'styles': [],
                    'children': [
                      {
                        'key': 'translations-popup-button',
                        'type': 'LhIconButton',
                        'actions': [
                          {
                            event: 'click',
                            method: '',
                            type: '',
                            url: ''
                          }
                        ],
                        'attributes': {
                          'name': 'translations-popup-button',
                          icon: 'file-alt',
                          transparent: true,
                          disabled: this.loadingJSON
                        },
                        'styles': [],
                        'children': []
                      }
                    ]
                  }
                  ]
              }]
            },
              {
                "key":"main-admin-dashboard",
                "type":"LhMainPage",
                "actions":[

                ],
                "attributes":[

                ],
                "styles":[

                ],
                "children":[
                  {
                    "key": "patient-browse-tab",
                    "type": "LhTabs",
                    "actions": [],
                    "attributes": {
                      "target": "patient-filter-popup-group",
                      "verticalTabs": null
                    },
                    "styles": [],
                    "children": [
                      {
                        "key": "patient-detail-tab",
                        "type": "LhDiv",
                        "actions": [],
                        "attributes": {
                          "label": "PATIENT_DETAIL"
                        },
                        "styles": [],
                        "children": [
                          {
                            "key": "patient-review-form",
                            "type": "LhForm",
                            "actions": [
                              {
                                "url": "/patients/change-status",
                                "type": "update",
                                "method": "POST",
                                "event": "submit"
                              }
                            ],
                            "attributes": {
                              "id": "patient-review-form",
                              "closeOnSave": true,
                              "includeTableInfo": "complex-browse-patients"
                            },
                            "styles": [],
                            "children": [
                              {
                                "key": "patient-info-title",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": {
                                  "class": "pt-3"
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "title-col",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": 12,
                                      "md": 12
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "title",
                                        "type": "LhH3",
                                        "actions": [],
                                        "attributes": {
                                          "content": "PATIENT_INFO"
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "patient-info-content",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": {
                                  "class": "pt-3"
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "left-col",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": 12,
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "patient-id",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "patient-id-label-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-id-label",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "PATIENT_ID"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          },
                                          {
                                            "key": "patient-id-value-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-id-value",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": 34
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "key": "patient-reference-number",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "patient-reference-number-label-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-reference-number-label",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "PATIENT_REFERENCE_NUMBER"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          },
                                          {
                                            "key": "patient-reference-number-value-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-reference-number-value",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "0123456789"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "key": "patient-birth-year",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "patient-birth-year-label-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-birth-year-label",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "PATIENT_BIRTHDAY"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          },
                                          {
                                            "key": "patient-birth-year-value-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-birth-year-value",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "08-2022"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "key": "patient-sex",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "patient-sex-label-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-sex-label",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "PATIENT_SEX"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          },
                                          {
                                            "key": "patient-sex-value-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-sex-value",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "M"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "key": "patient-created-at",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "patient-created-at-label-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-created-at-label",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "PATIENT_REGISTRATION_DATE"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          },
                                          {
                                            "key": "patient-created-at-value-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-created-at-value",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "2022-08-23"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "key": "right-col",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": 12,
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "patient-professional",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "patient-professional-label-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-professional-label",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "PATIENT_PROFESSIONAL"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          },
                                          {
                                            "key": "patient-professional-value-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-professional-name",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "Jordy Monkeyproof"
                                                },
                                                "styles": [],
                                                "children": []
                                              },
                                              {
                                                "key": "patient-professional-phone",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": ""
                                                },
                                                "styles": [],
                                                "children": []
                                              },
                                              {
                                                "key": "patient-professional-email",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "jordy@monkeyproof.be"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          }
                                        ]
                                      },
                                      {
                                        "key": "patient-hospital",
                                        "type": "LhRow",
                                        "actions": [],
                                        "attributes": [],
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": "patient-hospital-label-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-hospital-label",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": "PATIENT_HOSPITAL"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          },
                                          {
                                            "key": "patient-hospital-value-col",
                                            "type": "LhCol",
                                            "actions": [],
                                            "attributes": {
                                              "cols": 6
                                            },
                                            "styles": [],
                                            "children": [
                                              {
                                                "key": "patient-hospital-value",
                                                "type": "LhP",
                                                "actions": [],
                                                "attributes": {
                                                  "content": " CLINIQUE SAINT-JEAN VERANDERING"
                                                },
                                                "styles": [],
                                                "children": []
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "patient-review-title",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": {
                                  "class": "pt-3"
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "title-col",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": 12,
                                      "md": 12
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "title",
                                        "type": "LhH3",
                                        "actions": [],
                                        "attributes": {
                                          "content": "PATIENT_REVIEW"
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "patient-review-content",
                                "type": "LhRow",
                                "actions": [],
                                "attributes": {
                                  "class": "pt-3"
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "left-col",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": 12,
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "patient-guid",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "hidden",
                                          "name": "guid",
                                          "id": "guid",
                                          "value": "185ab6de04c3925ef09791176f35603f3e2f42b5c4a98c80ca52695b8946c56c19c53ab38f7778c428068e214f184018ce1731a8eec82ab25f3c3643620c932d"
                                        },
                                        "styles": [],
                                        "children": []
                                      },
                                      {
                                        "key": "patient-status",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "radio",
                                          "label": "APPROVE_PATIENT",
                                          "name": "patient-status",
                                          "id": "patient-status-cru",
                                          "value": null
                                        },
                                        "styles": [],
                                        "children": [
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [
                                              {
                                                "display": {
                                                  "#patient-reason-for-reject-cru": true
                                                },
                                                "required": {
                                                  "textarea#patient-reason-for-reject-cru": true
                                                }
                                              }
                                            ],
                                            "attributes": {
                                              "value": "Rejected",
                                              "content": "GENERAL_NO"
                                            },
                                            "styles": [],
                                            "children": []
                                          },
                                          {
                                            "key": null,
                                            "type": null,
                                            "actions": [
                                              {
                                                "display": {
                                                  "#patient-reason-for-reject-cru": false
                                                },
                                                "required": {
                                                  "textarea#patient-reason-for-reject-cru": false
                                                }
                                              }
                                            ],
                                            "attributes": {
                                              "value": "Approved",
                                              "content": "GENERAL_YES"
                                            },
                                            "styles": [],
                                            "children": []
                                          }
                                        ]
                                      }
                                    ]
                                  },
                                  {
                                    "key": "right-col",
                                    "type": "LhCol",
                                    "actions": [],
                                    "attributes": {
                                      "cols": 12,
                                      "md": 6
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "patient-reason-for-reject",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "textarea",
                                          "label": "PATIENT_REASON_FOR_REJECT",
                                          "name": "patient-reason-for-reject",
                                          "id": "patient-reason-for-reject-cru",
                                          "rows": 12,
                                          "validation": [
                                            {
                                              "type": "required",
                                              "translation_key": "GENERAL_VALIDATION_REQUIRED",
                                              "translation_key_custom": null
                                            }
                                          ]
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "key": "patient-resupply-tab",
                        "type": "LhDiv",
                        "actions": [],
                        "attributes": {
                          "label": "PATIENT_SUPPLY_REQUESTS"
                        },
                        "styles": [],
                        "children": [
                          {
                            "key": "supply-table-group",
                            "type": "LhDiv",
                            "actions": [],
                            "attributes": [],
                            "styles": [],
                            "children": [
                              {
                                "key": "complex-browse-supplies",
                                "type": "LhGrid",
                                "actions": [],
                                "attributes": {
                                  "url": "/supply-requests",
                                  "disableMultiSelect": false
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "row-0",
                                    "type": "LhGridRow",
                                    "actions": [
                                      []
                                    ],
                                    "attributes": [],
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "supply-request-date-requested-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_DATE_REQUESTED",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-id-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_ID",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-patient-id-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_PATIENT_ID",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-patient-year-of-birth-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_PATIENT_YEAR_OF_BIRTH",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-type-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_TYPE",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-status-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_STATUS",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-hcp-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_HCP",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-hospital-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "content": "SUPPLY_HOSPITAL",
                                          "cellType": "data",
                                          "checked": true,
                                          "showMobile": true
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      },
                                      {
                                        "key": "supply-request-action-heading",
                                        "type": "LhGridHeader",
                                        "actions": [],
                                        "attributes": {
                                          "cellType": "button",
                                          "class": "cell-sticky"
                                        },
                                        "styles": {
                                          "fontWeight": "900"
                                        },
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "key": "supply-request-browse-footer",
                                "type": "LhBrowseFooter",
                                "actions": [],
                                "attributes": [],
                                "styles": [],
                                "children": [
                                  {
                                    "key": "supply-request-complex-paginate",
                                    "type": "LhPagination",
                                    "actions": [],
                                    "attributes": {
                                      "target": "complex-browse-supplies",
                                      "pageSize": 10,
                                      "currentPage": 1,
                                      "totalPages": 0,
                                      "url": "/supply-requests",
                                      "layout": "prev, pager, next, jumper"
                                    },
                                    "styles": [],
                                    "children": []
                                  },
                                  {
                                    "key": "supply-request-browse-size-select",
                                    "type": "LhBrowsePageSizeSelect",
                                    "actions": [],
                                    "attributes": {
                                      "textBefore": "GENERAL_SHOW",
                                      "textAfter": "GENERAL_RESULTS"
                                    },
                                    "styles": [],
                                    "children": [
                                      {
                                        "key": "supply-request-browse-amount-select",
                                        "type": "LhInput",
                                        "actions": [],
                                        "attributes": {
                                          "type": "select",
                                          "content": {
                                            "10": 10,
                                            "25": 25,
                                            "50": 50,
                                            "100": 100
                                          },
                                          "value": 10,
                                          "name": "supply-request-search-result-amount",
                                          "id": "supply-request-search-result-amount",
                                          "typeInfo": "browseSize",
                                          "target": "complex-browse-supplies",
                                          "url": "/supply-requests"
                                        },
                                        "styles": [],
                                        "children": []
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "key": "patient-eligibility-tab",
                        "type": "LhDiv",
                        "actions": [],
                        "attributes": {
                          "label": "PATIENT_ELIGIBILITY"
                        },
                        "styles": [],
                        "children": [
                          {
                            "key": "question-1",
                            "type": "LhRow",
                            "actions": [],
                            "attributes": [],
                            "styles": [],
                            "children": [
                              {
                                "key": "label-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "label",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "eligibility2lQuestion1Text"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              },
                              {
                                "key": "value-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "value",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "Description"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "key": "question-2",
                            "type": "LhRow",
                            "actions": [],
                            "attributes": [],
                            "styles": [],
                            "children": [
                              {
                                "key": "label-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "label",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "eligibility2lQuestion2Text"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              },
                              {
                                "key": "value-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "value",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "23/08/2022"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "key": "question-3",
                            "type": "LhRow",
                            "actions": [],
                            "attributes": [],
                            "styles": [],
                            "children": [
                              {
                                "key": "label-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "label",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "eligibility2lQuestion3Text"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              },
                              {
                                "key": "value-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "value",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "eligibility2lQuestion3Answer1Text"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "key": "question-4",
                            "type": "LhRow",
                            "actions": [],
                            "attributes": [],
                            "styles": [],
                            "children": [
                              {
                                "key": "label-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "label",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "eligibility2lQuestion4Text"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              },
                              {
                                "key": "value-col",
                                "type": "LhCol",
                                "actions": [],
                                "attributes": {
                                  "cols": 6
                                },
                                "styles": [],
                                "children": [
                                  {
                                    "key": "value",
                                    "type": "LhP",
                                    "actions": [],
                                    "attributes": {
                                      "content": "eligibility2lQuestion4Answer1Text"
                                    },
                                    "styles": [],
                                    "children": []
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
          ]
      },
    }
  }
}
</script>

<style scoped lang="scss">
</style>
